<template>
  <section class="ct-icons">
    <div class="ct-icons__container container">
      <div class="content-type__content">
        <div
          v-if="slice?.Settings?.Title"
          class="content-type__heading heading heading--2"
        >
          {{ slice.Settings.Title }}
        </div>

        <p
          v-if="slice?.Settings?.Description"
          class="content-type__description"
        >
          {{ slice.Settings.Description }}
        </p>

        <ul
          v-if="slice?.Icons?.Icons"
          class="ct-icons__list"
        >
          <li
            v-for="icon in slice.Icons.Icons"
            :key="icon"
            class="ct-icons__item"
          >
            <component
              :is="getElement(icon.Link)"
              :to="icon.Link"
              class="ct-icons__link"
            >
              <component
                :is="loadIcon(icon.Icon)"
                class="ct-icons__icon icon"
                :class="`icon--${useDash(icon.Icon)}`"
              />
              {{ icon.Name }}
            </component>
          </li>
        </ul>

      <!--  -->
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { AppLink } from '#components'

defineProps({
  slice: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  slices: {
    type: Array,
    required: true,
  },
})

function getElement(to: any) {
  return to ? AppLink : 'div'
}

const { loadIcon } = useStrapiIcons()
</script>

<style src="~layers/app/components/Slices/Icons.css" />
